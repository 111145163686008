<template>
  <div class="base-container">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.base-container {
  padding: 0px 3vw;
}

@media screen and (max-width: 768px) {
  .base-container {
    padding: 0px 12px;
  }
}

@media screen and (max-width: 600px) {
  .base-container {
    padding: 0px 6px;
  }
}
</style>
