import { CUSTOMER_NATURES, CUSTOMER_TYPES } from '@/model/Customer'
import { USER_ROLES } from '@/model/User'

const messages: unknown = {
  hello: `Bonjour`,
  login: `Me connecter`,
  cannotConnectToServer: `Impossible de se connecter au serveur`,
  validate: `Valider`,
  close: `Fermer`,
  show: `Afficher`,
  actions: `Actions`,
  search: `Rechercher`,
  edit: `Modifier`,
  cancel: `Annuler`,
  delete: `Supprimer`,
  download: `Télécharger`,
  askDelete: `Êtes-vous sûr de vouloir supprimer cet élément ?`,
  editImage: `Modifier l'image`,
  addImage: `Ajouter une image`,
  invalidForm: `Formulaire invalide`,
  invalidPassword: `Mot de passe invalide`,
  passwordHackedIn: `Mot de passe déchiffré en {n} au maximum`,
  Countdown: {
    years: `{n} année | {n} années`,
    months: `{n} mois | {n} mois`,
    days: `{n} jour | {n} jours`,
    hours: `{n} heure | {n} heures`,
    minutes: `{n} minute | {n} minutes`,
    seconds: `{n} seconde | {n} secondes`,
  },
  expired: `Expiré`,
  HackedIn: {
    min: `moins de 1 seconde`,
    second: `{n} secondes`,
    minute: `{n} minutes`,
    hour: `{n} heures`,
    day: `{n} jours`,
    month: `{n} mois`,
    year: `{n} années`,
    thousand: `{n} millénaires`,
    million: `{n} millions d'années`,
    billion: `{n} milliard d'années`,
    max: `l'âge de l'univers`,
  },
  YesOrNo: {
    true: `Oui`,
    false: `Non`,
    null: `Non`,
    undefined: `Non`,
  },
  Yes: `Oui`,
  No: `Non`,
  Nav: {
    title: `Cap Emploi`,
    Previous: `Précédent`,
    Home: `Accueil`,
    Start: `Commencer`,
    Login: `Connexion`,
    AskResetPassword: `Nouveau mot de passe`,
    ResetPassword: `Choix du mot de pase`,
    ActivateAccount: `Activer mon compte`,
    MyAccount: `Mon compte`,
    Logout: `Déconnexion`,
    MyAccountShort: `Compte`,
    LogoutShort: `Déco`,
    Users: `Utilisateurs`,
    Customers: `Clients`,
    Customer: `Client`,
    Worksites: `Chantiers`,
    Worksite: `Chantier`,
    Tools: `Outils`,
    Tool: `Outil`,
    Document: `Document`,
    Messaging: `Messagerie`,
  },
  View: {
    Login: {
      title: `Se connecter`,
      validate: `Me connecter`,
      forgotMyPassword: `Mot de passe oublié`,
    },
    MyAccount: {
      title: `Mon compte`,
      changePassword: `Modifier mon mot de passe`,
      newPassword: `Nouveau mot de passe`,
    },
    ActivateAccount: {
      title: `Choisissez votre mot de passe pour activer votre compte`,
      validate: `Activer le compte`,
    },
    AskResetPassword: {
      title: `Demander un nouveau mot de passe`,
      message: `Pour réinitaliser votre mot de passe, veuillez saisir votre nom d'utilisateur ou votre adresse mail et suivez les instructions de l'email que vous recevrez.`,
      validate: `Valider`,
    },
    ResetPassword: {
      title: `Choisissez votre nouveau mot de passe`,
      validate: `Valider`,
    },
    Home: {
      title: `Cap Emploi`,
      missives: `Messages`,
    },
    Customers: {
      title: `Clients`,
    },
    Customer: {
      title: `Client`,
      worksites: `Chantiers du client`,
      documents: `Documents du client`,
      missives: `Messages du client`,
    },
    Worksites: {
      title: `Chantiers`,
    },
    Worksite: {
      title: `Chantier`,
      documents: `Documents du chantiers`,
    },
    Tools: {
      title: `Outils`,
    },
    Tool: {
      title: `Outil`,
    },
    Document: {
      title: `Document`,
    },
    Messaging: {
      title: `Messagerie`,
    },
    Missive: {
      title: `Message`,
    },
  },
  User: {
    usernameOrEmail: `Nom d'utilisateur`,
    createDate: `Création`,
    password: `Mot de passe`,
    username: `Nom d'utilisateur`,
    email: `Email`,
    role: `Rôle`,
    Roles: {
      undefined: `Rôle non défini`,
      '': `Rôle non défini`,
      [USER_ROLES.ADMIN]: `Administrateur`,
    },
    noData: `Aucun utilisateur n'a été trouvé`,
    activated: `Activé`,
    newPassword: `Nouveau mot de passe`,
    askActivateAccount: `Email d'activation`,
  },
  Tool: {
    add: `Ajouter un outil`,
    update: `Modifier l'outil`,
    delete: `Êtes-vous sûr de vouloir supprimer l'outil ?`,
    name: `Nom`,
    noData: `Aucun outil`,
  },
  customer: `Client`,
  customers: `Clients`,
  Customer: {
    download: `Télécharger le CSV clients`,
    add: `Ajouter un client`,
    update: `Modifier le client`,
    delete: `Êtes-vous sûr de vouloir supprimer le client ?`,
    deleteInfo: `Le client possède {worksite} chantiers et {document} documents, qui seront supprimés eux aussi.`,
    noData: `Aucun client`,
    type: `Type`,
    Types: {
      undefined: ``,
      null: ``,
      '': ``,
      [CUSTOMER_TYPES.PROSPECT]: `Prospect`,
      [CUSTOMER_TYPES.ANCIEN]: `Ancien`,
      [CUSTOMER_TYPES.ACTIF]: `Actif`,
    },
    nature: `Nature`,
    Natures: {
      undefined: ``,
      null: ``,
      '': ``,
      [CUSTOMER_NATURES.PARTICULIER]: `Particulier`,
      [CUSTOMER_NATURES.ENTREPRISE]: `Entreprise`,
      [CUSTOMER_NATURES.COLLECTIVITE]: `Collectivité`,
      [CUSTOMER_NATURES.ASSOCIATION]: `Association`,
    },
    name: `Nom`,
    firstname: `Prénom`,
    lastname: `Nom`,
    company: `Organisation`,
    address: `Adresse`,
    city: `Ville`,
    cp: `Code postal`,
    email: `Email`,
    birthdate: `Date de naissance`,
    assistance: `Aide`,
    phone: `Téléphone fixe`,
    mobile: `Téléphone portable`,
    contactFirstname: `Prénom du contact`,
    contactLastname: `Nom du contact`,
    contactEmail: `Email du contact`,
    contactPhone: `Téléphone du contact`,
    contactRelation: `Relation du contact`,
    billingAddress: `Adresse de facturation`,
    billingCity: `Ville de facturation`,
    billingCp: `Code postal de facturation`,
  },
  worksite: `Chantier`,
  worksites: `Chantiers`,
  Worksite: {
    download: `Télécharger le CSV chantiers`,
    add: `Ajouter un chantier`,
    update: `Modifier le chantier`,
    delete: `Êtes-vous sûr de vouloir supprimer le chantier ?`,
    deleteInfo: `Le chantier possède {document} documents, qui seront supprimés eux aussi.`,
    noData: `Aucun chantier`,
    customer: `Client`,
    type: `Type`,
    address: `Adresse`,
    city: `Ville`,
    cp: `Code postal`,
    workerCount: `Nombre d'ouvrier`,
    height: `Hauteur`,
    length: `Longueur`,
    width: `Largeur`,
    surface: `Surface`,
    time: `Temps`,
    predictionDate: `Date prévue`,
    completionDate: `Date de réalisation`,
    comment: `Commentaire`,
    tools: `Outils`,
    waste: `Évacuation des déchets`,
    done: `Fini`,
    Done: {
      '': ``,
      null: ``,
      undefiend: ``,
      true: `Fini`,
      false: `En cours`,
    },
  },
  Document: {
    add: `Ajouter un document`,
    update: `Modifier le document`,
    delete: `Êtes-vous sûr de vouloir supprimer le document ?`,
    download: `Télécharger le document`,
    noData: `Aucun document`,
    name: `Nom de fichier`,
    filename: `Nom de fichier`,
  },
  Missive: {
    add: `Ajouter un message`,
    update: `Modifier le message`,
    delete: `Êtes-vous sûr de vouloir supprimer l'échange de messages ?`,
    customer: `Client`,
    request: `Message`,
    requestedAt: `Date du message`,
    done: `État`,
    notdone: `En cours`,
    Done: {
      undefined: `En cours`,
      null: `En cours`,
      '': `En cours`,
      false: `En cours`,
      true: `Traité`,
    },
    doneAt: `Traité le`,
    response: `Réponse`,
    noData: `Aucun message`,
    messages: `Messages`,
    lastMessage: `Dernier message`,
    lastMessageAt: `Dernier message à`,
  },
}

export default messages
