import dayjs, { Dayjs } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import { __ } from '@/locales'
import { saveAs } from 'file-saver'

dayjs.extend(duration)

function* infinite() {
  let index = 0

  while (true) {
    yield index++
  }
}

const generator = infinite()

export const getUUID = (): string => {
  return `${generator.next().value}`
}

export const dateToCountdown = (
  date: string | Date | undefined,
  offsetValue: number,
  offsetUnit: dayjs.ManipulateType,
): string => {
  let res = ''
  const now = dayjs().toDate().getTime()

  if (!date) return ''
  const expiredDate = dayjs(date).add(offsetValue, offsetUnit)
  if (expiredDate.isBefore(now)) return __('expired')

  const distance = dayjs.duration((expiredDate.toDate().getTime() || 0) - now)
  const years = distance.years()
  const months = distance.months()
  const days = distance.days()
  const hours = distance.hours()
  const minutes = distance.minutes()
  const seconds = distance.seconds()

  if (years) {
    res += `${__('Countdown.years', { n: years })} `
  }
  if (months || years) {
    res += `${__('Countdown.months', { n: months })} `
  }
  if (days || months) {
    res += `${__('Countdown.days', { n: days })} `
  }
  if ((hours || days) && !years) {
    res += `${__('Countdown.hours', { n: hours })} `
  }
  if ((minutes || hours) && !years && !months) {
    res += `${__('Countdown.minutes', { n: minutes })} `
  }
  if ((seconds || minutes) && !years && !months && !days) {
    res += `${__('Countdown.seconds', { n: seconds })} `
  }

  return res
}

export const dateToCountdownH = (
  date: string | Date | undefined,
  offsetValue: number,
  offsetUnit: dayjs.ManipulateType,
): string => {
  let res = ''
  const now = dayjs().toDate().getTime()

  if (!date) return ''
  const expiredDate = dayjs(date).add(offsetValue, offsetUnit)
  if (expiredDate.isBefore(now)) return __('expired')

  const distance = dayjs.duration((expiredDate.toDate().getTime() || 0) - now)
  const hours = Math.floor(distance.asHours())
  const minutes = distance.minutes()
  const seconds = distance.seconds()

  if (hours) {
    res += `${__('Countdown.hours', { n: hours })} `
  }
  if (minutes || hours) {
    res += `${__('Countdown.minutes', { n: minutes })} `
  }
  if (seconds || minutes) {
    res += `${__('Countdown.seconds', { n: seconds })} `
  }

  return res
}

dayjs.extend(localizedFormat)

export const formatDatetime = (datetime: string): string => {
  return dayjs(datetime).format('DD/MM/YYYY à HH:mm:ss')
}

export const toHumanDatetime = (date: string | Date | undefined): string => {
  if (!date || date === '0000-00-00 00:00:00.000000') return ''
  return dayjs(date).format('DD/MM/YYYY à HH:mm:ss')
}

export const toHumanDate = (date: string | Date | undefined): string => {
  if (!date || date === '0000-00-00 00:00:00.000000') return ''
  return dayjs(date).format('DD/MM/YYYY')
}

export const getUserOS = (): string => {
  let OSName = 'Unknown OS'
  if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows'
  if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Macintosh'
  if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux'
  if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'Android'
  if (navigator.userAgent.indexOf('like Mac') !== -1) OSName = 'iOS'
  return OSName
}

export const getUserBrowser = (): string => {
  const nAgt = navigator.userAgent
  let browserName, verOffset, nameOffset
  if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    browserName = 'Opera'
  } else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browserName = 'Internet Explorer'
  } else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browserName = 'Chrome'
  } else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browserName = 'Safari'
  } else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browserName = 'Firefox'
  } else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset)
  }
  return browserName || 'Unknown Browser'
}

export const formatAddress = (
  address?: string,
  city?: string,
  cp?: string,
): string => {
  if (!address && !city && !cp) return ''
  if (!city && !cp) return `${address || ''}`
  return `${address || ''}, ${city || ''} ${cp || ''}`
}

export const formatNames = (firstname?: string, lastname?: string): string => {
  if (!firstname && !lastname) return ''
  if (!firstname) return `${lastname || ''}`
  if (!lastname) return `${firstname || ''}`
  return `${firstname || ''} ${lastname || ''}`
}

export const formatLongText = (
  text: string | undefined,
  length = 20,
): string => {
  if (!text) return ''
  return `${text.slice(0, length)}${text.length > length ? '...' : ''}`
}

interface CSVColumnOptions<T> {
  name: string
  getValue: (data: T) => string
}

export interface CSVOptions<T> {
  filename?: string
  dataSeparator?: string
  lineSeparator?: string
  columns: CSVColumnOptions<T>[]
}

export const downloadCSV = (data: unknown[], options: CSVOptions<never>) => {
  const csv = formatToCSV(data, options)
  const anchor = document.createElement('a')
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
  anchor.target = '_blank'
  anchor.download = `${options.filename || 'data'}.csv`
  anchor.click()
  anchor.remove()
}

const formatToCSV = (data: unknown[], options: CSVOptions<never>) => {
  const dataSeparator = options.dataSeparator || ','
  const lineSeparator = options.lineSeparator || '\n'
  let csv = options.columns.map((c) => c.name).join(dataSeparator)
  csv += lineSeparator
  for (const item of data) {
    const column = []
    for (const option of options.columns) {
      column.push(option.getValue(item as never).replace(/\n/g, ' '))
    }
    csv += column.join(dataSeparator)
    csv += lineSeparator
  }
  return csv
}

export class Utils {
  get locale(): string {
    return dayjs.locale()
  }

  set locale(lang: string) {
    dayjs.locale(lang)
  }

  constructor() {
    this.locale = 'fr'
  }

  toFirstUpperCase(text: string) {
    if (typeof text !== 'string') return ''
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  UTCToMoment(date: string | undefined): Dayjs {
    if (!date) return dayjs()
    return dayjs(date)
  }

  nowToMoment(): Dayjs {
    return dayjs()
  }

  momentToUTC(dayjs: Dayjs): string {
    return dayjs.toISOString()
  }

  nowToUTC(): string {
    return dayjs().toISOString()
  }

  HTMLToServer(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('YYYY/MM/DD')
  }

  UTCToHuman(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('MMMM YYYY')
  }

  UTCToYear(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('YYYY')
  }

  UTCToMonth(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('MMMM')
  }

  UTCToDay(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('DDDD')
  }

  UTCToShort(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('L')
  }
}

export const utils = new Utils()

export const getImgUrl = (pic: string): string => {
  if (!pic) return ''
  return require(`@/assets/${pic}`)
}

export const toHumanOctet = (size: number): string => {
  if (size < 1024) return `${size} octets`
  if (size < 1024 * 1024) return `${Math.round(size / 1024)} Ko`
  if (size < 1024 * 1024 * 1024) return `${Math.round(size / 1024 / 1024)} Mo`
  return `${Math.round(size / 1024 / 1024 / 1024)} Go`
}

export const downloadFile = (base64: string, filename: string) => {
  saveAs(base64, filename)
}
