import { Item } from './Item'

export enum USER_ROLES {
  ADMIN = 'admin',
}

export interface User extends Item {
  id: number
  role?: USER_ROLES
  username?: string
  email?: string
  activated?: boolean
}
