import { __ } from '@/locales'
import { Item } from './Item'

export enum CUSTOMER_TYPES {
  PROSPECT = `PROSPECT`,
  ACTIF = `ACTIF`,
  ANCIEN = `ANCIEN`,
}

export enum CUSTOMER_NATURES {
  PARTICULIER = `PARTICULIER`,
  ENTREPRISE = `ENTREPRISE`,
  COLLECTIVITE = `COLLECTIVITE`,
  ASSOCIATION = `ASSOCIATION`,
}

export interface Customer extends Item {
  worksiteIds?: number[]
  documentIds?: number[]

  nature?: CUSTOMER_NATURES
  type?: CUSTOMER_TYPES
  firstname?: string
  lastname?: string
  company?: string
  address?: string
  city?: string
  cp?: string
  email?: string
  birthdate?: string
  assistance?: string
  phone?: string
  mobile?: string

  contactFirstname?: string
  contactLastname?: string
  contactEmail?: string
  contactPhone?: string
  contactRelation?: string

  billingAddress?: string
  billingCity?: string
  billingCp?: string
}

export interface CustomerSearchdata {
  dateBegin?: string
  dateEnd?: string
  type?: string
  nature?: string
  firstname?: string
  lastname?: string
  address?: string
  city?: string
  cp?: string
  email?: string
  birthdate?: string
  assistance?: string
  phone?: string
  mobile?: string
}

export const NULL_CUSTOMER: Customer = {
  type: CUSTOMER_TYPES.PROSPECT,
}
