import { createApp } from 'vue'
import i18n from './locales'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import '@digithia/input/text'
import '@digithia/input/password'
import '@digithia/input/number'
import '@digithia/input/select'
import '@digithia/input/checkbox'
import '@digithia/input/radio'
import '@digithia/input/textarea'
import '@digithia/input/file'
import '@digithia/input/phone'
import '@digithia/ui'

const pinia = createPinia()
const app = createApp(App).use(pinia).use(router).use(i18n)

const requireComponent = require.context(
  './components/base',
  false,
  /Base[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = (fileName.split('/').pop() || '').replace(/\.\w+$/, '')
  app.component(componentName, componentConfig.default)
})

app.mount('#app')
