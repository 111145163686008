import {
  createRouter,
  createWebHistory,
  RouteLocationRaw,
  RouteRecordRaw,
} from 'vue-router'
import ViewMain from '@/views/ViewMain.vue'
import { useStoreAuth } from '@/store/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: ViewMain,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/ViewHome.vue'),
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('../views/ViewMyAccount.vue'),
      },
      {
        path: 'customers',
        name: 'Customers',
        component: () => import('../views/ViewCustomers.vue'),
      },
      {
        path: 'worksites',
        name: 'Worksites',
        component: () => import('../views/ViewWorksites.vue'),
      },
      {
        path: 'tools',
        name: 'Tools',
        component: () => import('../views/ViewTools.vue'),
      },
      {
        path: 'customer/:id',
        name: 'Customer',
        component: () => import('../views/ViewCustomer.vue'),
      },
      {
        path: 'worksite/:id',
        name: 'Worksite',
        component: () => import('../views/ViewWorksite.vue'),
      },
      {
        name: 'Document',
        path: 'documents/:id',
        component: () => import('../views/ViewDocument.vue'),
      },
      {
        path: 'tool/:id',
        name: 'Tool',
        component: () => import('../views/ViewTool.vue'),
      },
      {
        path: 'messaging',
        name: 'Messaging',
        component: () => import('../views/ViewMessaging.vue'),
      },
      {
        path: 'missive/:id',
        name: 'Missive',
        component: () => import('../views/ViewMissive.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/ViewLogin.vue'),
  },
  {
    path: '/activate-account',
    name: 'ActivateAccount',
    component: () => import('../views/ViewActivateAccount.vue'),
  },
  {
    path: '/ask-reset-password',
    name: 'AskResetPassword',
    component: () => import('../views/ViewAskResetPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ViewResetPassword.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const storeAuth = useStoreAuth()
  storeAuth.updatePage(to.name?.toString() || '')

  if (to.query.token) {
    storeAuth.setToken(`${to.query.token}`)
  }

  if (!storeAuth.isLoggedIn) {
    if (to.name !== 'Login' && to.name !== 'AskResetPassword') {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export const goTo = (path: string | RouteLocationRaw) => {
  router.push(path)
}

export const goBack = () => {
  router.back()
}

export const reload = () => {
  window.location.reload()
}

export const openInMap = (address?: string) => {
  window.open(`https://maps.google.com/?q=${address}`, '_blank')
}

export const openInNewTab = (link?: string) => {
  window.open(link, '_blank')
}

export const callPhone = (phone?: string) => {
  if (!phone) return
  window.open(`tel:${phone}`)
}

export const sendEmail = (email?: string) => {
  if (!email) return
  window.open(`mailto:${email}`)
}

export default router
