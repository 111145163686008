import { createI18n } from 'vue-i18n'
import fr from './fr'

type MessageSchema = typeof fr

const i18n = createI18n<[MessageSchema], 'fr-FR'>({
  locale: 'fr-FR',
  fallbackLocale: 'fr-FR',
  messages: {
    'fr-FR': fr,
  },
})

const loadedLanguages = ['fr']

const setI18nLanguage = (lang: string) => {
  i18n.global.locale = lang
  const html = document.querySelector('html')
  html?.setAttribute('lang', lang)
  return lang
}

export const loadLanguageAsync = async (lang: string) => {
  if (i18n.global.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(`@/locales/${lang}.ts`).then((messages) => {
    i18n.global.setLocaleMessage(lang, messages.default[lang])
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n

export const __ = i18n.global.t
