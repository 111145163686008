import { defineStore } from 'pinia'
import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { User, USER_ROLES } from '@/model/User'
import { TokenData } from '@/model/TokenData'

export interface StoreStateAuth {
  currentPage: string
  token: string
  tokendata: TokenData | undefined
}

export const useStoreAuth = defineStore('auth', {
  state: (): StoreStateAuth => ({
    currentPage: '',
    get token(): string {
      return localStorage.getItem('token') || ''
    },
    set token(token: string) {
      localStorage.setItem('token', token || '')
    },
    get tokendata(): TokenData | undefined {
      return localStorage.getItem('tokendata')
        ? JSON.parse(localStorage.getItem('tokendata') || '{}')
        : undefined
    },
    set tokendata(tokendata: TokenData | undefined) {
      localStorage.setItem('tokendata', JSON.stringify(tokendata || ''))
    },
  }),
  getters: {
    user: (state): User | undefined => {
      return state.tokendata?.user
    },
    role: (state): USER_ROLES | undefined => {
      return state.tokendata?.user?.role
    },
    isLoggedIn: (state): boolean => {
      return (
        !!state.token &&
        new Date().getTime() < (state.tokendata?.exp || 0) * 1000
      )
    },
  },
  actions: {
    async login(
      username: string,
      password?: string,
    ): Promise<ServerResponse<string>> {
      const json = await apiHandler.post('users/login', { username, password })
      if (json.success) {
        this.setToken(json.data)
      }
      return json
    },
    async activateAccount(
      id: number,
      password: string,
    ): Promise<ServerResponse<string>> {
      const json = await apiHandler.post(`users/${id}/activate-account`, {
        password,
      })
      if (json.success) {
        this.setToken(json.data)
      }
      return json
    },
    async updateAccount(
      username: string,
      email: string,
    ): Promise<ServerResponse<void>> {
      const json = await apiHandler.put(`users/update-self`, {
        username,
        email,
      })
      return json
    },
    async resetPassword(password: string): Promise<ServerResponse<string>> {
      const json = await apiHandler.post(
        `users/${this.user?.id}/reset-password`,
        {
          password,
        },
      )
      if (json.success) {
        this.setToken(json.data)
      }
      return json
    },
    async askResetPassword(username: string): Promise<ServerResponse<void>> {
      const json = await apiHandler.post('users/ask-reset-password', {
        username,
      })
      return json
    },
    async changePassword(password: string): Promise<ServerResponse<void>> {
      const json = await apiHandler.put(`users/change-password`, {
        password,
      })
      return json
    },
    logout(): void {
      this.token = ''
      this.tokendata = {}
    },
    setToken(token: string): void {
      this.token = token
      this.extractTokenData()
    },
    extractTokenData(): void {
      if (!this.token) {
        this.tokendata = undefined
        return
      }
      const base64Url = this.token.split('.')[1]
      if (!base64Url) {
        this.tokendata = undefined
        return
      }
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const tokendata = JSON.parse(window.atob(base64) || '{}')
      this.tokendata = tokendata
    },
    updatePage(page: string): void {
      this.currentPage = page
    },
  },
})
